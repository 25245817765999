import React, { Fragment } from 'react';



const Home = () => {

  return (
    <Fragment>

        <div class="content">
            
            <h1>Home</h1>
     <div id="home">

     
<p>
Welcome, User! 
</p>
<p>
Thank you for visiting my site. 
<br />
I see this space as a small shop (dükkan) in the vast village of the internet, surrounded by large shopping malls run by big companies. This is a cosy corner where you can sit, have some tea or coffee, relax, and look around. It’s a piece of heritage I want to build with my own hands and hopefully preserve for the future, even though I’m not sure where it will lead.

I wanted a space like this for several meaningful reasons.
</p>
<p>
This is a place where I can share my things and thoughts online with the visitors who are willing to listen. No need to be seen in your favourite social media feed every time you refresh, buried in the vast sea of information, and no need for any algorithm to push me in front of you. I’m also a bit shy when it comes to social media. Instead of sharing there, I have this space to publish my reflections. <a href='/clog'>Captain's Log</a> is where I plan to do this.
</p>
<p>
I want to use this site as a testing platform. I’m a self-taught developer, which means I occasionally break things as I learn. Naturally, I need an online space to test what I’ve been working on locally. So, you might encounter bugs from time to time! When that happens, I’ll remind myself of what Guide says: 'Don’t Panic'. And if you happen to see something glitch, feel free to tell yourself the same. And please <a href="mailto:orhun.ugur93@gmail.com">let me know</a> so I can fix it!
</p>
<p>
This site is also a place to showcase my other projects. I love learning by practicing. For instance, my biggest passion these days is to develop my own heritage platform. I’m currently working on <a href='/diy-heritage'>DIY-Heritage</a> and thinking of adding new projects here over time.
</p>
<p>
Finally, I hope this space will lead to new conversations and potential collaborations. Whether it’s feedback on the website, discussions about shared interests, or exploring ways to work together (<a href='/cv'>CV</a> attached, for those who need to know), I’m always open to connecting. Feel free to reach out if something sparks your interest!
</p>
<p>By the way, why not <a href='/tarot'>pick a card</a> while you are here?</p>
</div>
        </div>

    </Fragment>
   );
   

};

export default Home;
